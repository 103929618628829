import React, { createContext, lazy, Suspense, useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSessionStorage } from 'react-use';
import { IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { home, ticket, personCircle } from 'ionicons/icons';
import { StringParam, useQueryParams } from 'use-query-params';
import { LoadingCenter } from './components/Loading';
import useLiff from './hooks/useLiff';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { useAuthControllerToken, UserEntity } from './API';
import { RestfulProvider } from 'restful-react';

export const AuthContext = createContext<UserEntity | undefined>(undefined);

const App: React.FC = () => {
  const [isReady, setIsReady] = useState(false);
  const [queryParams] = useQueryParams({ case: StringParam });
  const [createdCase, setCreatedCase] = useSessionStorage<string | null>('createdCase', null);
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<UserEntity>();
  const { mutate: authToken } = useAuthControllerToken({});
  const liff = useLiff();

  useEffect(() => {
    if (queryParams.case) {
      // LIFFログインでリダイレクトされるのでクエリパラメータをセッションストレージに保持
      setCreatedCase(queryParams.case as string);
    }
  }, [setCreatedCase, queryParams.case]);

  useEffect(() => {
    // LIFFの初期化が完了
    if (!liff?.loading) {
      // LIFFのアクセストークンで認証トークンを取得
      authToken({ provider: 'LINE', providerToken: liff.getAccessToken() as string, createdCase: createdCase! }).then((auth) => {
        setUser(auth.user);
        setToken(auth.accessToken);
        setIsReady(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liff?.loading]);

  return (
    <IonApp>
      {!isReady ? (
        <LoadingCenter />
      ) : (
        <RestfulProvider
          base=""
          resolve={(data) => data}
          requestOptions={(url, method, requestBody): Partial<RequestInit> => ({
            headers: { Authorization: 'Bearer ' + token },
          })}
        >
          <Suspense fallback={<LoadingCenter />}>
            <AuthContext.Provider value={user}>
              <IonReactRouter>
                {/* <IonTabs> */}
                <IonRouterOutlet>
                  <Route path="/liff/home" component={lazy(() => import('./pages/Home'))} exact={true} />
                  <Route path="/liff/coupons" component={lazy(() => import('./pages/Coupon'))} exact={true} />
                  <Route path="/liff/userinfo" component={lazy(() => import('./pages/UserInfo'))} exact={true} />
                  <Route
                    exact
                    path="/liff"
                    render={() => (user?.member ? <Redirect to="/liff/coupons" /> : <Redirect to="/liff/userinfo" />)}
                  />
                </IonRouterOutlet>
                {/* <IonTabBar slot="bottom">
                  <IonTabButton tab="home" href="/home">
                    <IonIcon icon={home} />
                    <IonLabel>ホーム</IonLabel>
                  </IonTabButton>
                  <IonTabButton tab="coupons" href="/coupons">
                    <IonIcon icon={ticket} />
                    <IonLabel>クーポン</IonLabel>
                  </IonTabButton>
                  <IonTabButton tab="mypage" href="/mypage">
                    <IonIcon icon={personCircle} />
                    <IonLabel>マイページ</IonLabel>
                  </IonTabButton>
                </IonTabBar> */}
                {/* </IonTabs> */}
              </IonReactRouter>
            </AuthContext.Provider>
          </Suspense>
        </RestfulProvider>
      )}
    </IonApp>
  );
};

export default App;
