import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import liff from '@line/liff';

const useLiff = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const liffIds = [
    { '/liff/coupons': process.env.REACT_APP_LIFF_COUPON! },
    { '/liff/userinfo': process.env.REACT_APP_LIFF_USERINFO! },
    { '/liff': process.env.REACT_APP_LIFF_ID! },
  ];

  useEffect(() => {
    const initLiff = async () => {
      const obj = liffIds.find((liff) => Object.keys(liff)[0].startsWith(location.pathname)) || liffIds[liffIds.length - 1];
      const prop = Object.keys(obj)[0] as keyof typeof obj;
      const liffId = obj[prop] || process.env.REACT_APP_LIFF_ID!;

      liff.ready.then(() => {
        if (!liff.isInClient() && !liff.isLoggedIn()) {
          return liff.login();
        }
        setLoading(false);
      });
      await liff.init({ liffId });
    };
    initLiff();
  }, []);

  return { loading, ...liff };
};

export default useLiff;
