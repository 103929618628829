/* Generated by restful-react */

import React from "react";
import { Get, GetProps, useGet, UseGetProps, Mutate, MutateProps, useMutate, UseMutateProps } from "restful-react";
export interface AuthTokenRequestBody {
  provider: "LINE";
  providerToken: string;
  createdCase?: string;
}

export interface MemberAttributeEntity {
  id: string;
  memberId: string;
  key: "Name" | "Gender" | "Birthday" | "zipCode" | "Case";
  text?: string;
  values?: string[];
  createdAt?: string;
  updatedAt?: string;
}

export interface MemberEntity {
  id?: string;
  companyId: string;
  name: string;
  users?: UserEntity[];
  attributes?: MemberAttributeEntity[];
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

export interface UserEntity {
  id?: string;
  companyId: string;
  provider: "LINE";
  memberId?: string;
  member?: MemberEntity;
  providerUserId: string;
  providerUserName: string;
  pictureUrl?: string;
  follow: "UnFollow" | "Follow";
  createdCase?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

export interface AuthTokenResponseBody {
  accessToken: string;
  user: UserEntity;
}

export interface CouponEntity {
  id?: string;
  companyId: string;
  couponHistory?: CouponHistoryEntity[];
  type: "Period" | "Registration" | "Birthday";
  title: string;
  description?: string;
  pictureUrl?: string;
  code?: string;
  buttonText?: string;
  url?: string;
  beforeText: string;
  startAt?: string;
  endAt?: string;
  displayStartAt?: string;
  displayEndAt?: string;
  validBeforeDays?: number;
  validAfterDays?: number;
  displayBeforeDays?: number;
  displayAfterDays?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface CouponHistoryEntity {
  id?: string;
  memberId?: string;
  member: MemberEntity;
  couponId: string;
  coupon: CouponEntity;
  createdAt?: string;
  updatedAt?: string;
}

export interface PostMemberAttributeDto {
  key: "Name" | "Gender" | "Birthday" | "zipCode" | "Case";
  /**
   * テキスト形式の属性
   */
  text?: string;
  /**
   * 選択形式の属性
   */
  values?: string[];
}

export interface PostMemberDto {
  attributes: PostMemberAttributeDto[];
}

export interface PutMemberAttributeDto {
  id: string;
  key: "Name" | "Gender" | "Birthday" | "zipCode" | "Case";
  /**
   * テキスト形式の属性
   */
  text?: string;
  /**
   * 選択形式の属性
   */
  values?: string[];
}

export interface PutMemberDto {
  attributes: PutMemberAttributeDto[];
}

export interface PostCouponHistoryDto {
  couponId: string;
}

export type ApiControllerInfoProps = Omit<GetProps<string, unknown, void, void>, "path">;

export const ApiControllerInfo = (props: ApiControllerInfoProps) => (
  <Get<string, unknown, void, void>
    path={`/api/v1`}
    
    {...props}
  />
);

export type UseApiControllerInfoProps = Omit<UseGetProps<string, unknown, void, void>, "path">;

export const useApiControllerInfo = (props: UseApiControllerInfoProps) => useGet<string, unknown, void, void>(`/api/v1`, props);


export type ApiControllerTokenProps = Omit<MutateProps<void, unknown, void, void, void>, "path" | "verb">;

export const ApiControllerToken = (props: ApiControllerTokenProps) => (
  <Mutate<void, unknown, void, void, void>
    verb="POST"
    path={`/api/v1`}
    
    {...props}
  />
);

export type UseApiControllerTokenProps = Omit<UseMutateProps<void, unknown, void, void, void>, "path" | "verb">;

export const useApiControllerToken = (props: UseApiControllerTokenProps) => useMutate<void, unknown, void, void, void>("POST", `/api/v1`, props);


export type AuthControllerTokenProps = Omit<MutateProps<AuthTokenResponseBody, unknown, void, AuthTokenRequestBody, void>, "path" | "verb">;

export const AuthControllerToken = (props: AuthControllerTokenProps) => (
  <Mutate<AuthTokenResponseBody, unknown, void, AuthTokenRequestBody, void>
    verb="POST"
    path={`/api/v1/auth/token`}
    
    {...props}
  />
);

export type UseAuthControllerTokenProps = Omit<UseMutateProps<AuthTokenResponseBody, unknown, void, AuthTokenRequestBody, void>, "path" | "verb">;

export const useAuthControllerToken = (props: UseAuthControllerTokenProps) => useMutate<AuthTokenResponseBody, unknown, void, AuthTokenRequestBody, void>("POST", `/api/v1/auth/token`, props);


export type CouponsControllerGetProps = Omit<GetProps<CouponEntity[], unknown, void, void>, "path">;

/**
 * クーポン一覧取得
 */
export const CouponsControllerGet = (props: CouponsControllerGetProps) => (
  <Get<CouponEntity[], unknown, void, void>
    path={`/api/v1/coupons`}
    
    {...props}
  />
);

export type UseCouponsControllerGetProps = Omit<UseGetProps<CouponEntity[], unknown, void, void>, "path">;

/**
 * クーポン一覧取得
 */
export const useCouponsControllerGet = (props: UseCouponsControllerGetProps) => useGet<CouponEntity[], unknown, void, void>(`/api/v1/coupons`, props);


export type MembersControllerPostProps = Omit<MutateProps<MemberEntity, unknown, void, PostMemberDto, void>, "path" | "verb">;

/**
 * 会員登録
 */
export const MembersControllerPost = (props: MembersControllerPostProps) => (
  <Mutate<MemberEntity, unknown, void, PostMemberDto, void>
    verb="POST"
    path={`/api/v1/members`}
    
    {...props}
  />
);

export type UseMembersControllerPostProps = Omit<UseMutateProps<MemberEntity, unknown, void, PostMemberDto, void>, "path" | "verb">;

/**
 * 会員登録
 */
export const useMembersControllerPost = (props: UseMembersControllerPostProps) => useMutate<MemberEntity, unknown, void, PostMemberDto, void>("POST", `/api/v1/members`, props);


export interface MembersControllerPutPathParams {
  id: string
}

export type MembersControllerPutProps = Omit<MutateProps<MemberEntity, unknown, void, PutMemberDto, MembersControllerPutPathParams>, "path" | "verb"> & MembersControllerPutPathParams;

/**
 * 会員編集
 */
export const MembersControllerPut = ({id, ...props}: MembersControllerPutProps) => (
  <Mutate<MemberEntity, unknown, void, PutMemberDto, MembersControllerPutPathParams>
    verb="PUT"
    path={`/api/v1/members/${id}`}
    
    {...props}
  />
);

export type UseMembersControllerPutProps = Omit<UseMutateProps<MemberEntity, unknown, void, PutMemberDto, MembersControllerPutPathParams>, "path" | "verb"> & MembersControllerPutPathParams;

/**
 * 会員編集
 */
export const useMembersControllerPut = ({id, ...props}: UseMembersControllerPutProps) => useMutate<MemberEntity, unknown, void, PutMemberDto, MembersControllerPutPathParams>("PUT", (paramsInPath: MembersControllerPutPathParams) => `/api/v1/members/${paramsInPath.id}`, {  pathParams: { id }, ...props });


export interface LineWebhookControllerPostPathParams {
  channelId: string
}

export type LineWebhookControllerPostProps = Omit<MutateProps<void, unknown, void, void, LineWebhookControllerPostPathParams>, "path" | "verb"> & LineWebhookControllerPostPathParams;

/**
 * LINE Webhook
 */
export const LineWebhookControllerPost = ({channelId, ...props}: LineWebhookControllerPostProps) => (
  <Mutate<void, unknown, void, void, LineWebhookControllerPostPathParams>
    verb="POST"
    path={`/api/v1/webhook/line/${channelId}`}
    
    {...props}
  />
);

export type UseLineWebhookControllerPostProps = Omit<UseMutateProps<void, unknown, void, void, LineWebhookControllerPostPathParams>, "path" | "verb"> & LineWebhookControllerPostPathParams;

/**
 * LINE Webhook
 */
export const useLineWebhookControllerPost = ({channelId, ...props}: UseLineWebhookControllerPostProps) => useMutate<void, unknown, void, void, LineWebhookControllerPostPathParams>("POST", (paramsInPath: LineWebhookControllerPostPathParams) => `/api/v1/webhook/line/${paramsInPath.channelId}`, {  pathParams: { channelId }, ...props });


export type CouponHistoryControllerPostProps = Omit<MutateProps<CouponHistoryEntity, unknown, void, PostCouponHistoryDto, void>, "path" | "verb">;

/**
 * 会員登録
 */
export const CouponHistoryControllerPost = (props: CouponHistoryControllerPostProps) => (
  <Mutate<CouponHistoryEntity, unknown, void, PostCouponHistoryDto, void>
    verb="POST"
    path={`/api/v1/coupon-history`}
    
    {...props}
  />
);

export type UseCouponHistoryControllerPostProps = Omit<UseMutateProps<CouponHistoryEntity, unknown, void, PostCouponHistoryDto, void>, "path" | "verb">;

/**
 * 会員登録
 */
export const useCouponHistoryControllerPost = (props: UseCouponHistoryControllerPostProps) => useMutate<CouponHistoryEntity, unknown, void, PostCouponHistoryDto, void>("POST", `/api/v1/coupon-history`, props);

