import { IonSpinner } from '@ionic/react';
import styled from 'styled-components';

export const LoadingCenter = styled(IonSpinner)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;
